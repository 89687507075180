import React, { Component } from "react";

import Modal from "./modal";
import PrivacyNotice from "./notice";
import {
  Footer,
  FooterWrapper,
  FlexBetween,
  Flex,
  Ul,
  Li,
  LinkStyle,
  PoweredBy,
  PoweredByText,
} from "./styles";
import SNA from '../../assets/svg/logo-dark.svg'
class AppFooter extends Component {
  state = {
    showModal: false,
  };

  showPrivacy = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <Footer>
        <FooterWrapper>
          <FlexBetween>
            <Flex>
              <Ul>
                <img src={SNA} alt='powered by SNA' width={75} height={31}  style={{display:'block',marginRight:'40px'}}/>
                <Li style={LinkStyle} onClick={this.showPrivacy}>
                  Privacy Policy
                </Li>
              </Ul>
            </Flex>
            <PoweredBy>
              <PoweredByText>A Project by </PoweredByText>
              <svg
                width="55"
                height="28"
                viewBox="0 0 55 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.53137 2.37524C5.53137 1.04859 6.61252 0.141479 7.98907 0.141479C8.83767 0.141479 9.42222 0.504322 9.80567 0.997567L9.30907 1.25269C9.03882 0.884175 8.53593 0.623383 7.98907 0.623383C6.93937 0.623383 6.14737 1.3434 6.14737 2.37524C6.14737 3.39574 6.94567 4.1271 7.98907 4.1271C8.53593 4.1271 9.03882 3.86064 9.30907 3.49779L9.81192 3.74158C9.40967 4.24048 8.83767 4.60332 7.98907 4.60332C6.61882 4.60898 5.53137 3.70189 5.53137 2.37524Z"
                  fill="#3C4859"
                />
                <path
                  d="M10.3524 2.96525C10.3524 2.04113 11.0501 1.32678 12.081 1.32678C12.7096 1.32678 13.0867 1.55923 13.3507 1.87105L12.9924 2.16019C12.7599 1.87672 12.4707 1.75766 12.1124 1.75766C11.3833 1.75766 10.9119 2.26791 10.9119 2.95958C10.9119 3.65125 11.377 4.16717 12.1124 4.16717C12.4707 4.16717 12.7661 4.03677 12.9924 3.75897L13.3507 4.05378C13.0867 4.36561 12.7096 4.59804 12.081 4.59804C11.0501 4.60938 10.3524 3.88937 10.3524 2.96525Z"
                  fill="#3C4859"
                />
                <path
                  d="M17.5245 4.52965V2.55102H14.7274V4.52965H14.1302V0.21521H14.7274V2.06912H17.5245V0.21521H18.1217V4.52965H17.5245Z"
                  fill="#3C4859"
                />
                <path
                  d="M19.2406 2.85717V0.21521H19.844V2.84016C19.844 3.62822 20.3092 4.12713 21.1955 4.12713C22.0755 4.12713 22.5532 3.62822 22.5532 2.84016V0.21521H23.1566V2.84583C23.1566 3.91736 22.4966 4.60337 21.1955 4.60337C19.9006 4.60904 19.2406 3.91736 19.2406 2.85717Z"
                  fill="#3C4859"
                />
                <path
                  d="M24.2692 4.52965V0.21521H26.4C27.2297 0.21521 27.7389 0.668765 27.7389 1.31508C27.7389 1.85935 27.3366 2.22219 26.8966 2.30724C27.4057 2.38094 27.8269 2.8515 27.8269 3.36742C27.8269 4.05909 27.3177 4.52965 26.444 4.52965H24.2692ZM27.1229 1.39445C27.1229 1.00893 26.8337 0.697113 26.2995 0.697113H24.8663V2.08613H26.2995C26.84 2.08613 27.1229 1.78565 27.1229 1.39445ZM27.2172 3.31072C27.2172 2.91386 26.9155 2.56803 26.3372 2.56803H24.8663V4.05342H26.3372C26.8903 4.05342 27.2172 3.76995 27.2172 3.31072Z"
                  fill="#3C4859"
                />
                <path
                  d="M6.19776 8.77625H9.73661C10.7738 8.77625 11.7606 9.14479 12.6972 9.88179C13.2566 10.4034 13.6212 10.8853 13.7846 11.3218C13.9732 11.7924 14.0675 12.1893 14.0675 12.5238V12.949C14.0675 13.8447 13.6338 14.7122 12.7726 15.5569C12.1252 16.0728 11.5029 16.4073 10.8932 16.5604C10.4469 16.6624 10.0886 16.7135 9.82461 16.7135H6.14747C5.77661 16.7135 5.46861 16.5094 5.23602 16.1069C5.19202 15.9991 5.16687 15.9084 5.16687 15.8234V9.70037C5.16687 9.29786 5.43087 9.00305 5.95261 8.81025C6.04061 8.78758 6.12861 8.77625 6.19776 8.77625ZM7.37946 10.7492V14.7462H9.33432C10.0949 14.7462 10.7612 14.4854 11.3332 13.9638C11.6789 13.5896 11.8549 13.2041 11.8549 12.8072V12.6712C11.8549 12.0815 11.4903 11.5656 10.7675 11.1234C10.3149 10.8739 9.79317 10.7549 9.20861 10.7549H7.37946V10.7492Z"
                  fill="#3C4859"
                />
                <path
                  d="M15.7144 8.77625H22.5847C22.9619 8.77625 23.2699 8.97466 23.515 9.36588C23.559 9.48495 23.5842 9.5813 23.5842 9.64936V9.87045C23.5842 10.1879 23.3642 10.4544 22.9242 10.6698C22.7796 10.7209 22.6476 10.7492 22.5219 10.7492H16.9024V11.764H21.2459C21.799 11.764 22.1699 11.9625 22.3647 12.3537C22.4213 12.4671 22.4527 12.5748 22.4527 12.6712V12.8072C22.4527 13.2097 22.1887 13.5046 21.667 13.6973C21.579 13.72 21.4973 13.7314 21.4219 13.7314H16.9024V14.7462H22.4024C23.0499 14.7462 23.4459 15.0297 23.5904 15.5909V15.8574C23.5904 16.1635 23.3767 16.4244 22.9556 16.6398C22.8362 16.6908 22.7293 16.7191 22.6224 16.7191H15.6642C15.2933 16.7191 14.9853 16.515 14.7527 16.1125C14.7087 16.0048 14.6836 15.9141 14.6836 15.8291V9.70604C14.6836 9.30353 14.9476 9.00872 15.4693 8.81592C15.5573 8.78758 15.639 8.77625 15.7144 8.77625Z"
                  fill="#3C4859"
                />
                <path
                  d="M27.2486 8.77625H32.0823C32.5349 8.77625 32.8618 8.99738 33.0755 9.43389C33.0881 9.49062 33.0943 9.53596 33.0943 9.56997V9.87045C33.0943 10.1823 32.8743 10.4487 32.4469 10.6698C32.2772 10.7209 32.1389 10.7492 32.0321 10.7492H26.4252V11.747H29.7566C31.1081 11.747 32.1201 12.1325 32.7801 12.9036C32.9875 13.1927 33.0943 13.4422 33.0943 13.6406V14.8255C33.0943 15.2848 32.7486 15.7383 32.0635 16.1862C31.4035 16.5377 30.7561 16.7191 30.1212 16.7191H25.1555C24.8224 16.7191 24.5269 16.549 24.2755 16.2032C24.2189 16.0898 24.1875 15.9935 24.1875 15.9027V15.6873C24.1875 15.2848 24.4515 14.9899 24.9732 14.7972C25.0926 14.7632 25.2246 14.7462 25.3755 14.7462H30.8881V13.7484H27.3618C26.3812 13.7484 25.5326 13.4706 24.8286 12.9206C24.4075 12.5408 24.1938 12.1553 24.1938 11.764V10.7606C24.1938 10.1086 24.6904 9.55296 25.6772 9.08807C26.2555 8.87831 26.7772 8.77625 27.2486 8.77625Z"
                  fill="#3C4859"
                />
                <path
                  d="M34.7662 8.77625H34.9297C35.3068 8.77625 35.6211 8.96333 35.8662 9.34321C35.9354 9.46228 35.9731 9.60964 35.9731 9.77976V15.7667C35.9731 16.1975 35.6902 16.5094 35.1245 16.7021C35.0617 16.7135 35.0051 16.7191 34.9674 16.7191H34.716C34.3325 16.7191 34.0182 16.515 33.7794 16.1125C33.7291 16.0048 33.704 15.9141 33.704 15.8291V9.70604C33.704 9.30353 33.9742 9.00872 34.5148 8.81592C34.6091 8.78758 34.6908 8.77625 34.7662 8.77625Z"
                  fill="#3C4859"
                />
                <path
                  d="M40.9767 8.77625H44.4904C44.8485 8.77625 45.163 8.969 45.4208 9.35454C45.4648 9.49628 45.4896 9.59831 45.4896 9.65503V9.87612C45.4896 10.1936 45.2696 10.4601 44.8296 10.6755C44.6852 10.7266 44.5534 10.7549 44.4274 10.7549H41.467C40.3607 10.7549 39.5435 11.1574 39.0092 11.9568C38.871 12.2233 38.8018 12.4954 38.8018 12.7732C38.8018 13.5556 39.3109 14.1565 40.3229 14.5818C40.6812 14.6952 41.0144 14.7518 41.3224 14.7518H43.2774V13.7484C43.2774 13.2268 43.5033 12.8753 43.9622 12.6995C44.0819 12.6428 44.2074 12.6144 44.346 12.6144H44.44C44.9178 12.6144 45.2633 12.8583 45.4711 13.3515C45.4833 13.4082 45.4896 13.4536 45.4896 13.4876V15.8517C45.4896 16.1579 45.2759 16.4187 44.8548 16.6341C44.7356 16.6851 44.6285 16.7135 44.5219 16.7135H40.8572C39.8955 16.7135 38.9527 16.362 38.0224 15.6646C37.4944 15.1827 37.1738 14.8029 37.0607 14.5251C36.7464 13.9581 36.5892 13.4195 36.5892 12.8979V12.6031C36.5892 11.6563 37.0292 10.7662 37.9029 9.92717C38.8458 9.16179 39.8704 8.77625 40.9767 8.77625Z"
                  fill="#3C4859"
                />
                <path
                  d="M47.1366 8.77625H47.2937C47.6081 8.77625 47.9285 8.94066 48.2555 9.27515C50.3174 11.1064 51.8322 12.4387 52.7937 13.2778V9.84211C52.7937 9.34887 53.0451 9.00871 53.5481 8.82163C53.6611 8.79325 53.7618 8.77625 53.85 8.77625H54.0007C54.3777 8.77625 54.6859 8.97466 54.9311 9.36588C54.9748 9.48495 55 9.5813 55 9.64936V15.8574C55 16.1635 54.7863 16.4244 54.3651 16.6398C54.2459 16.6908 54.1388 16.7191 54.0322 16.7191H53.787C53.4725 16.7191 53.0829 16.4754 52.6114 15.9821L48.3059 12.1836V15.7667C48.3059 16.1975 48.0292 16.5094 47.4763 16.7021C47.4133 16.7135 47.3629 16.7191 47.3251 16.7191H47.08C46.7092 16.7191 46.4011 16.515 46.1685 16.1125C46.1248 16.0048 46.0996 15.9141 46.0996 15.8291V9.70604C46.0996 9.30353 46.3637 9.00872 46.8851 8.81592C46.9796 8.78758 47.0611 8.77625 47.1366 8.77625Z"
                  fill="#3C4859"
                />
                <path
                  d="M6.19776 19.3212H6.35487C6.71947 19.3212 7.02117 19.5083 7.26632 19.8881C7.33547 20.0072 7.37317 20.1546 7.37317 20.3247V25.2911H12.8732C13.5206 25.2911 13.9166 25.5746 14.0612 26.1358V26.4023C14.0612 26.7085 13.8475 26.9693 13.4263 27.1847C13.3069 27.2357 13.2 27.2641 13.0932 27.2641H6.14747C5.77661 27.2641 5.46861 27.06 5.23602 26.6575C5.19202 26.5497 5.16687 26.459 5.16687 26.374V20.251C5.16687 19.8485 5.43087 19.5536 5.95261 19.3609C6.04061 19.3268 6.12861 19.3212 6.19776 19.3212Z"
                  fill="#3C4859"
                />
                <path
                  d="M19.052 19.3212H19.2405C19.6931 19.3212 20.064 19.65 20.3405 20.302C22.44 24.0325 23.5211 25.9714 23.584 26.1188V26.4193C23.584 26.7538 23.3388 27.026 22.8485 27.23C22.7417 27.2527 22.6537 27.2641 22.5908 27.2641H22.4337C21.9748 27.2641 21.604 26.9239 21.3211 26.2492C19.8754 23.6753 19.1462 22.377 19.1211 22.3657C17.5057 25.2968 16.6508 26.8219 16.5628 26.9409C16.324 27.1564 16.0725 27.2698 15.8148 27.2698H15.664C15.2931 27.2698 14.9851 27.0656 14.7463 26.6631C14.7085 26.5441 14.6897 26.4533 14.6897 26.3796V26.2606C14.6897 26.1132 14.8971 25.688 15.3183 24.9793L18.2788 19.7067C18.4988 19.4459 18.7628 19.3212 19.052 19.3212Z"
                  fill="#3C4859"
                />
                <path
                  d="M25.2247 19.3215H30.467C31.215 19.3215 31.9064 19.639 32.535 20.274C32.9058 20.7559 33.0944 21.2095 33.0944 21.6347V21.9635C33.0944 22.3887 32.9121 22.8309 32.5538 23.2901C32.9121 23.7664 33.0944 24.2029 33.0944 24.5998V24.974C33.0944 25.4786 32.8367 25.9888 32.3212 26.5161C31.7241 27.0093 31.127 27.2531 30.5361 27.2531H25.1807C24.8098 27.2531 24.5018 27.049 24.2692 26.6464C24.2252 26.5387 24.2001 26.448 24.2001 26.363V20.24C24.2001 19.8374 24.4641 19.5426 24.9858 19.3499C25.0675 19.3272 25.1492 19.3215 25.2247 19.3215ZM26.4064 21.2889V22.3037H30.8881V21.2889H26.4064ZM26.4064 24.2709V25.2858H30.8881V24.2709H26.4064Z"
                  fill="#3C4859"
                />
                <path d="M1.35771 0H0V2.90276H1.35771V0Z" fill="#3C4859" />
                <path
                  d="M1.35771 2.8573H0V5.8054H1.35771V2.8573Z"
                  fill="#3C4859"
                />
                <path
                  d="M1.35771 5.80566H0V8.75379H1.35771V5.80566Z"
                  fill="#3C4859"
                />
                <path
                  d="M1.35771 8.7594H0V11.7075H1.35771V8.7594Z"
                  fill="#3C4859"
                />
                <path
                  d="M1.35771 11.7074H0V14.6555H1.35771V11.7074Z"
                  fill="#3C4859"
                />
                <path
                  d="M1.35771 14.6558H0V17.6039H1.35771V14.6558Z"
                  fill="#3C4859"
                />
                <path
                  d="M1.35771 17.6036H0V20.5518H1.35771V17.6036Z"
                  fill="#3C4859"
                />
                <path
                  d="M1.35771 20.5574H0V23.5055H1.35771V20.5574Z"
                  fill="#3C4859"
                />
                <path
                  d="M1.35771 23.5057H0V26.4539H1.35771V23.5057Z"
                  fill="#3C4859"
                />
                <path
                  d="M1.35771 26.4197H0V27.4345H1.35771V26.4197Z"
                  fill="#3C4859"
                />
              </svg>
            </PoweredBy>
          </FlexBetween>

          <Modal closeModal={this.closeModal} isOpen={this.state.showModal} style={{ height: "500px" }}>
            <PrivacyNotice close={this.closeModal} />
          </Modal>
        </FooterWrapper>
      </Footer>
    );
  }
}

export default AppFooter;
